import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalAlertsService, QuestionBase, QuestionProvider, TextboxQuestion, TranslationService } from 'fre-base-components';
import { Observable, of } from 'rxjs';
import { FreAuthService } from '../_services/fre-auth.service';

@Component({
  selector: 'fre-first-access',
  templateUrl: './first-access.component.html',
  styleUrls: ['./first-access.component.scss']
})
export class FirstAccessComponent extends QuestionProvider implements OnInit {

  public loading = false;

  constructor(
    private tService: TranslationService,
    private mService: ModalAlertsService,
    private authService: FreAuthService,
    public route: Router,
  ) { super(); }

  ngOnInit() {}

  getQuestions(): Observable<QuestionBase<any>[]> {
    return of([
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_name'),
        key: 'usrNome',
        type: 'text',
        layout: {
          cols: 50,
          colSmall: 100
        },
        required: true
      }),
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_email'),
        key: 'usrEmail',
        type: 'email',
        layout: {
          cols: 50,
          colSmall: 100
        },
        required: true
      }),
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_user'),
        key: 'usrLogin',
        type: 'text',
        layout: {
          cols: 50,
          colSmall: 100
        },
        required: true
      }),
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_password'),
        key: 'usrPassword',
        type: 'password',
        required: true,
        layout: {
          cols: 50,
          colSmall: 100
        },
      })
    ]);
  }

  request(val: any) {
    this.loading = true;
    this.authService.firstRegisterSystem(val).subscribe(() => {
      this.loading = false;
      this.route.navigate(['/login']);
    }, (err) => {
      this.loading = false;
      this.mService.openModalAlert(
        'cancel',
        this.tService.t('gnc_title_error'),
        'red',
        err.error,
        'modalAlertBase'
      );

    });
  }

}
