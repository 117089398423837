import { AbstractControl, ValidationErrors } from '@angular/forms';
import { QuestionBase, QuestionProvider, TextboxQuestion, TranslationService } from 'fre-base-components';
import { Observable, of } from 'rxjs';

export class ChangePassword extends QuestionProvider {
  constructor(private tService: TranslationService) { super(); }

  getQuestions(): Observable<QuestionBase<any>[]> {
    return of([
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_user'),
        key: 'usrLogin',
        maxlength: 80,
        type: 'text',
        required: true
      }),
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_password'),
        key: 'usrPassword',
        maxlength: 80,
        type: 'password',
        required: true,
      }),
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_confirm_password'),
        key: 'usrConfirmPassword',
        maxlength: 80,
        type: 'password',
        required: true,
        validators: [this.validatePassword.bind(this)]
      })
    ]);
  }

  private validatePassword(abs: AbstractControl): ValidationErrors | null {
      if (abs.parent !== null) {
        const confirmPass = abs.value;
        const pass = abs.parent.value.usrPassword;

        if (confirmPass !== pass) {
          return { remoteError: this.tService.t('frm_lbl_error_password') };
        }

        return null;

      }
      return null;
  }
}
