<div fxLayout="row" fxLayoutAlign="center center" class="logo-main">
    <img  class="logo" src="assets/LogoFreNew.svg" alt="Logo">
</div>
<div fxLayout="row" fxLayoutAlign="center center" class="login-main">
    <fre-dynamic-form
        [title]="'frm_title_firstAccess' | translate"
        [showClearButton]="false"
        [subtitle]="'frm_subtitle_firstAccess' | translate"
        [isProcessing]="loading"
        [submitText]="'frm_btn_firstAccess' | translate"
        (onSubmit)="request($event)"
        [questionService]="this">
        <ng-template #actionsTemplate>
            <button mat-raised-button color="accent" (click)="route.navigate(['/login'])">{{'frm_title_login' | translate}}</button>
        </ng-template>
    </fre-dynamic-form>
</div>
