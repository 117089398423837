export const pt = {
    gnc_btn_close: 'Fechar',
    gnc_btn_save: 'Salvar',
    gnc_title_register: 'Cadastro',
    gnc_title_success: 'Sucesso',
    gnc_title_error: 'Erro',
    gnc_title_alert: 'Alerta',
    gnc_lbl_on: 'Ligar',
    gnc_lbl_off: 'Desligar',

    frm_title_login: 'Conectar-se',
    frm_subtitle_login: 'Entre com Suas Credenciais.',
    frm_btn_login: 'Entrar',
    frm_lbl_user: 'Usuário',
    frm_lbl_email: 'E-mail',
    frm_lbl_password: 'Senha',
    frm_lbl_confirm_password: 'Confirmar a Senha',

    frm_title_changePassword: 'Alterar Senha',
    frm_subtitle_changePassword: 'Informe os Dados Abaixo.',
    frm_btn_changePassword: 'Alterar',

    frm_lbl_error_password: 'Senhas Não Conferem',

    alr_success_changePassword: 'Senha Alterado com Sucesso.',
    alr_error_changePassword: 'Falha ao Alterar Senha.',
    alr_success_newPassword: 'Nova Senha: ',
    alr_alert_isChangePassword: 'Deseja Mesmo Resetar a Senha?',

    frm_title_firstAccess: 'Primeiro Acesso',
    frm_subtitle_firstAccess: 'Informe os Dados do Primeiro Usuário do Sistema.',
    frm_btn_firstAccess: 'Cadastrar',
    frm_lbl_name: 'Nome',

    tbl_title_btn_reset_password: 'Resetar Senha',

    fle_form_title: 'Cadastrar Arquivo',
    fle_tab_add: 'Cadastro',
    fle_tab_edit: 'Consulta',
    lbl_fle_name: 'Nome do Arquivo (Sem Extensão)',
    lbl_fle_file: 'Arquivo',
    lbl_fle_site: 'Site',
    lbl_fle_user: 'Usuário',
    lbl_fle_success_upload: 'Arquivo salvo com sucesso.',
    lbl_fle_error_upload: 'Houve um erro ao tentar salvar o arquivo',

    lbl_fle_list_remove: 'Remover',
    lbl_fle_remove_item: 'Tem certeza que deseja excluir este arquivo?',
    lbl_fle_remove_success: 'Sucesso ao remover arquivo.',
    lbl_fle_remove_error: 'Houve um erro ao tentar salvar o arquivo',
};
