<div fxLayout="row" fxLayoutAlign="center center" class="logo-main">
    <img  class="logo" src="assets/LogoFreNew.svg" alt="Logo">
</div>
<div fxLayout="row" fxLayoutAlign="center center" class="login-main">
    <fre-dynamic-form
        *ngIf="!changeForm"
        [title]="'frm_title_login' | translate"
        [showClearButton]="false"
        [subtitle]="'frm_subtitle_login' | translate"
        [isProcessing]="loading"
        [submitText]="'frm_btn_login' | translate"
        (onSubmit)="entrar($event)"
        [questionService]="this">
        <ng-template #actionsTemplate>
            <button mat-raised-button color="accent" (click)="changeForm = !changeForm" type="button">
                {{'frm_title_changePassword' | translate}}
            </button>
        </ng-template>
    </fre-dynamic-form>
  
    <fre-dynamic-form
          *ngIf="changeForm"
          [title]="'frm_title_changePassword' | translate"
          [showClearButton]="false"
          [subtitle]="'frm_subtitle_changePassword' | translate"
          [isProcessing]="loadingPass"
          [submitText]="'frm_btn_changePassword' | translate"
          (onSubmit)="password($event)"
          [questionService]="this.formChangePassword">
          <ng-template #actionsTemplate>
            <button mat-raised-button color="accent" (click)="changeForm = !changeForm" type="button">
              {{'frm_title_login' | translate}}
            </button>
          </ng-template>
    </fre-dynamic-form>
</div>
