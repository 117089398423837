import { Component, OnInit } from '@angular/core';
import { ModalAlertsService, QuestionBase, QuestionProvider, TextboxQuestion, TranslationService } from 'fre-base-components';
import { FreAuthService } from '../_services/fre-auth.service';
import * as uuid from 'uuid';
import { Observable, of } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ChangePassword } from './form/changePassword';

@Component({
  selector: 'fre-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends QuestionProvider implements OnInit {

  public loading = false;
  public loadingPass = false;
  public changeForm = false;

  public formChangePassword = new ChangePassword(this.tService);

  constructor(
    private tService: TranslationService,
    private mService: ModalAlertsService,
    private authService: FreAuthService,
    private modal: ModalAlertsService
  ) { super(); }

  ngOnInit() {}

  getQuestions(): Observable<QuestionBase<any>[]> {
    return of([
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_user'),
        key: 'usrLogin',
        type: 'text',
        required: true
      }),
      new TextboxQuestion({
        label: this.tService.t('frm_lbl_password'),
        key: 'usrPassword',
        type: 'password',
        required: true
      })
    ]);
  }

  entrar(val: any) {
    this.loading = true;

    const obj = {
      usrLogin: val.usrLogin,
      usrPassword: val.usrPassword,
      usrLocalToken: uuid.v4(),
      usrLang: this.tService.getCurrentLanguage()
    };

    this.authService.login(obj).subscribe((res) => {
      // TODO: Melhor o  Root build no Base Componentes.
      this.loading = false;

      if (res.IS_RESET_PASSWORD) {
        this.modal.openModalFormBuild({
          text: this.tService.t('frm_title_changePassword'),
          width: '400px',
          height: 'auto',
          subTitle: `${this.tService.t('frm_lbl_user')}: ${res.USR_LOGIN}`,
          submitTitle: this.tService.t('frm_btn_changePassword'),
          disableClose: true,
          questionFB: [
            new TextboxQuestion({
              label: this.tService.t('frm_lbl_password'),
              key: 'usrPassword',
              maxlength: 80,
              type: 'password',
              required: true,
            }),
            new TextboxQuestion({
              label: this.tService.t('frm_lbl_confirm_password'),
              key: 'usrConfirmPassword',
              maxlength: 80,
              type: 'password',
              required: true,
              validators: [this.validatePassword.bind(this)]
            })
          ]
        }).subscribe((newPassword: any) => {
          const newObj = {
            usrLogin: res.USR_LOGIN,
            usrNewPassword: newPassword.usrPassword
          };

          this.authService.changePassoword(newObj).subscribe((r) => {
            this.modal.openModalAlert(
              'done',
              this.tService.t('gnc_title_success'),
              'green',
              this.tService.t('alr_success_changePassword'),
              'modalAlertBase');
          }, (e) => {
            this.modal.openModalLog(
              'cancel',
              this.tService.t('gnc_title_error'),
              'red',
              'modalLog',
              this.tService.t('alr_error_changePassword'),
              e.error);
          });

        });
      } else {

        this.authService.startSession(res);
        window.location.reload();

      }
    }, (err) => {
      this.loading = false;
      console.log(err);
      this.mService.openModalAlert(
        'cancel',
        this.tService.t('gnc_title_error'),
        'red',
        err.error,
        'modalAlertBase'
      );
    });
  }

  password(val: any) {
    this.loadingPass = true;

    const obj = {
      usrLogin: val.usrLogin,
      usrNewPassword: val.usrPassword
    };

    this.authService.changePassoword(obj).subscribe((r) => {
      this.loadingPass = false;
      this.modal.openModalAlert(
        'done',
        this.tService.t('gnc_title_success'),
        'green',
        this.tService.t('alr_success_changePassword'),
        'modalAlertBase');
      this.changeForm = false;
    }, (e) => {
      this.loadingPass = false;
      this.modal.openModalLog(
        'cancel',
        this.tService.t('gnc_title_error'),
        'red',
        'modalLog',
        this.tService.t('alr_error_changePassword'),
        e.error);
    });
  }

  private validatePassword(abs: AbstractControl): ValidationErrors | null {
    if (abs.parent !== null) {
      const confirmPass = abs.value;
      const pass = abs.parent.value.usrPassword;

      if (confirmPass !== pass) {
        return { remoteError: this.tService.t('frm_lbl_error_password') };
      }

      return null;

    }
    return null;
}

}
