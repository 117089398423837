import { Component, ViewChild, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FreRootSource, FreNavOption, FreFullRootMetadata, TranslationService } from 'fre-base-components';
import { FreAuthService } from './_services/fre-auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'fre-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends FreRootSource implements OnInit {

  @ViewChild('appDrawer', { read: true }) appDrawer: MatSidenav;

  showNav = false;

  loadingRouteConfig: boolean;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: FreAuthService,
              private tService: TranslationService,
              private http: HttpClient
              ) {
    super();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {

        this.activatedRoute.children.forEach((r) => {
          this.showNav = !r.snapshot.data.hideNavigation;
        });

        this.activeLink = this.router.url;
      }
    );

    this.didChangeMenu.subscribe((menu: FreNavOption) => {
      if (menu.link.split('/')[1] === 'logout') {
        this.authService.logout();
      } else {
        this.router.navigate([menu.link]);
      }
    });
  }

  getSystemMetadata(): Observable<FreFullRootMetadata> {
    const params = new HttpParams().set('LANG', this.tService.getCurrentLanguage());

    return this.http.get<any>(`${environment.apiURL}appMenu`, {
      headers: this.authService.getAuthHeaders(),
      params
    }).pipe(
      switchMap((menuApp) => {
          const homeMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Home'));
          const servMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Serviços') || m.title.startsWith('Services'));
          const prodMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Produtos') || m.title.startsWith('Products'));
          const abouMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Quem Somos') || m.title.startsWith('About Us'));
          const downMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Downloads'));
          const footMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Rodapé') || m.title.startsWith('Footer'));
          const headMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Cabeçalho') || m.title.startsWith('Header'));
          const contMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('Contato') || m.title.startsWith('Contact'));
          const faqsMenu = menuApp.menuMetadata.filter((m) => m.title.startsWith('FAQ'));
          const restMenu = menuApp.menuMetadata.filter((el) =>  !homeMenu.includes(el) &&
                                                                !servMenu.includes(el) &&
                                                                !prodMenu.includes(el) &&
                                                                !abouMenu.includes(el) &&
                                                                !downMenu.includes(el) &&
                                                                !footMenu.includes(el) &&
                                                                !headMenu.includes(el) &&
                                                                !contMenu.includes(el) &&
                                                                !faqsMenu.includes(el));

          // const miscMenu = [{
          //   key: 'misc',
          //   link: 'files',
          //   order: 1,
          //   title: 'Arquivos'
          // }];

          homeMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          servMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          prodMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          abouMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          downMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          footMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          headMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          faqsMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });

          contMenu.forEach((row) => {
            row.title = row.title.split('-')[1].trim();
          });
          return  of({
          schemas: [...[
            {
              title: 'cadastro',
              options: restMenu.map((row) => row.key)
            },
            // {
            //   title: 'misc',
            //   options: miscMenu.map((row) => row.key)
            // },
            {
              title: 'header',
              options: headMenu.map((row) => row.key)
            },
            {
              title: 'footer',
              options: footMenu.map((row) => row.key)
            },
            {
              title: 'home',
              options: homeMenu.map((row) => row.key)
            },
            {
              title: 'servicos',
              options: servMenu.map((row) => row.key)
            },
            {
              title: 'produtos',
              options: prodMenu.map((row) => row.key)
            },
            {
              title: 'sobre',
              options: abouMenu.map((row) => row.key)
            },
            {
              title: 'downloads',
              options: downMenu.map((row) => row.key)
            },
            {
              title: 'contato',
              options: contMenu.map((row) => row.key)
            },
            {
              title: 'faq',
              options: faqsMenu.map((row) => row.key)
            }
          ], ...menuApp.customMenu.schema],
          menus: [...[
            {
              key: 'cadastro',
              title: 'Cadastro',
              icon: 'add',
              options: restMenu
            },
            // {
            //   key: 'misc',
            //   title: 'Miscelâneo',
            //   icon: 'article',
            //   options: miscMenu
            // },
            {
              key: 'header',
              title: 'Cabeçalho',
              icon: 'view_headline',
              options: headMenu
            },
            {
              key: 'footer',
              title: 'Rodapé',
              icon: 'vertical_align_bottom',
              options: footMenu
            },
            {
              key: 'home',
              title: 'Home',
              icon: 'home',
              options: homeMenu
            },
            {
              key: 'servicos',
              title: 'Serviços',
              icon: 'home_repair_service',
              options: servMenu
            },
            {
              key: 'produtos',
              title: 'Produtos',
              icon: 'store',
              options: prodMenu
            },
            {
              key: 'sobre',
              title: 'Sobre',
              icon: 'info',
              options: abouMenu
            },
            {
              key: 'downloads',
              title: 'Downloads',
              icon: 'get_app',
              options: downMenu
            },
            {
              key: 'contato',
              title: 'Contato',
              icon: 'alternate_email',
              options: contMenu
            },
            {
              key: 'faq',
              title: 'FAQ',
              icon: 'help',
              options: faqsMenu
            }
          ], ...menuApp.customMenu.menus]
        });
      })
    );

  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
      }
  });
  }

}
