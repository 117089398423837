import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalAlertsService, TranslationService } from 'fre-base-components';
import { Observable, Observer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FreIChangePassword, FreIFirstAccess, FreILogin, FreIResetPassword, FreISession } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})

export class FreAuthService {
    get isAuthenticated(): boolean {
        return this.currentSession ? true : false;
    }

    public currentSession: FreISession;

    constructor(
        private http: HttpClient,
        private route: Router,
        private tService: TranslationService,
        private mService: ModalAlertsService
    ) {
        this.currentSession = this.getCurrentSession();
    }

    public getCurrentLocale(): string {
        return this.tService.getCurrentLanguage();
    }

    public getAuthHeaders(): HttpHeaders {

        if (this.isAuthenticated) {
            return new HttpHeaders({
                USRTOKEN: this.currentSession.USR_TOKEN,
                USRLOCALTOKEN: this.currentSession.USR_LOCAL_TOKEN
            });
        } else {
            return new HttpHeaders();
        }

    }

    public login(loginObj: FreILogin): Observable<FreISession> {
        return this.tService.setCurrentLanguage(loginObj.usrLang.substring(0, 2)).pipe(
          switchMap(() => this.http.post<FreISession>(`${environment.apiURL}login`, loginObj))
        );
    }

    public logout(): void {
        this.http.get<any>(`${environment.apiURL}logout`, {headers: this.getAuthHeaders()})
        .subscribe(() => {
            this.destroyCurrentSession();
        }, (err) => {
            console.log('Erro Logout: ', err);
            this.mService.openModalAlert(
                'cancel',
                this.tService.t('gnc_title_error'),
                'red',
                err.error,
                'modalAlertBase'
            );
        });
    }

    public firstRegisterSystem(user: FreIFirstAccess): Observable<{msg: string}> {
        return this.http.post<{msg: string}>(`${environment.apiURL}firstRegisterSystem`, user);
    }

    public checkSession(): Observable<{msg: string}> {
        return this.http.get<{msg: string}>(`${environment.apiURL}checkSession`, {headers: this.getAuthHeaders()});
    }

    public guardAccess(screen: string, lng: string): Observable<{isAccess: boolean}> {

      const params = new HttpParams().set('LANG', lng).set('SCREEN', screen);

      return this.http.get<{isAccess: boolean}>(`${environment.apiURL}guardAccess?`, {headers: this.getAuthHeaders(), params});
    }

    public startSession(user: FreISession): void {
        localStorage.setItem('currentSession', JSON.stringify(user));
        this.currentSession = user;
    }

    private getCurrentSession(): FreISession {
        return JSON.parse(localStorage.getItem('currentSession'));
    }

    public destroyCurrentSession(): void {
        localStorage.removeItem('currentSession');
        this.currentSession = undefined;
        this.route.navigate(['/login']);
    }

    public changePassoword(user: FreIChangePassword): Observable<{msg: string}> {
      return this.http.post<{msg: string}>(`${environment.apiURL}changePassword`, user);
    }

    public resetPassword(user: FreIResetPassword): Observable<{newPassword: string}> {
      return this.http.post<{newPassword: string}>(`${environment.apiURL}resetPassword`, user, {
        headers: this.getAuthHeaders()
      });
    }
}
