import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Component
import { ExampleComponent } from './example/example.component';
import { FirstAccessComponent } from './first-access/first-access.component';
import { LoginComponent } from './login/login.component';
// Guard
import { FreAuthGuard } from './_guard/fre-auth.guard';

const routes: Routes = [
  // Login
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [FreAuthGuard],
    data: {
      breadcrumb: 'Tela de Login',
      hideNavigation: true
    }
  },

  // First Access
  {
    path: 'firstAccess',
    component: FirstAccessComponent,
    canActivate: [FreAuthGuard],
    data: {
      breadcrumb: 'Tela de Cadastro do Primeiro Usuário.',
      hideNavigation: true
    }
  },

  // Crud
  {
    path: 'cadastro/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Cadastro'
    }
  },

  {
    path: 'header/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Cabeçalho'
    }
  },

  {
    path: 'footer/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Rodapé'
    }
  },

  {
    path: 'home/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Home'
    }
  },

  {
    path: 'servicos/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Serviços'
    }
  },

  {
    path: 'produtos/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Produtos'
    }
  },

  {
    path: 'sobre/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Sobre'
    }
  },

  {
    path: 'downloads/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Downloads'
    }
  },

  {
    path: 'contato/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'Contato'
    }
  },

  {
    path: 'faq/:screen',
    canActivate: [FreAuthGuard],
    loadChildren: () => import('./crud/crud.module').then(m => m.CrudModule),
    data: {
      breadcrumb: 'FAQ'
    }
  },

  // Componets
  {
    path: 'home',
    component: ExampleComponent,
    canActivate: [FreAuthGuard],
    data: {
      breadcrumb: 'Exemplo'
    }
  },

  {
    path: 'misc/files',
    loadChildren: () => import('./files/files.module').then(m => m.FilesModule),
    canActivate: [FreAuthGuard],
    data: {
      breadcrumb: 'Arquivos'
    }
  },

  // Redirects
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [FreAuthGuard]
})
export class AppRoutingModule { }
