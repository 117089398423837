import { FirstAccessComponent } from '../first-access/first-access.component';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { FreAuthService } from '../_services/fre-auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()

export class FreAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: FreAuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (!this.authService.isAuthenticated && route.component !== LoginComponent && route.component !== FirstAccessComponent) {
      console.log('Usuário Não Autenticado');

      this.router.navigate(['/login']);

      return false;
    } else if (
      this.authService.isAuthenticated && (route.component === LoginComponent || route.component === FirstAccessComponent)
    ) {
      console.log('Usuário Já Está Autenticado');

      this.router.navigate(['/home']);

      return false;
    } else {

      let currentScreen = '';

      if (route.params.screen) {
        currentScreen = route.params.screen;
      } else if (route.routeConfig.path) {
        const arrPath = route.routeConfig.path.split('/');

        currentScreen = arrPath[arrPath.length - 1];
      }

      if (route.component !== LoginComponent && route.component !== FirstAccessComponent) {
        this.authService.checkSession().subscribe(() => {}, (err) => {
          this.authService.destroyCurrentSession();
        });

        return this.authService.guardAccess(currentScreen, this.authService.currentSession.USR_LANG).pipe(
          map((r) => {
            if (!r.isAccess) {
              this.router.navigate(['/home']);
            }

            return r.isAccess;
          })
        );
      } else {
        return true;
      }

    }
  }
}
