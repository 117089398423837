export const en = {
    gnc_btn_close: 'Close',
    gnc_btn_save: 'Save',
    gnc_title_register: 'Register',
    gnc_title_success: 'Success',
    gnc_title_error: 'Error',
    gnc_title_alert: 'Alert',
    gnc_lbl_on: 'Turn On',
    gnc_lbl_off: 'Turn Off',

    frm_title_login: 'Login',
    frm_subtitle_login: 'Enter Your Credentials.',
    frm_btn_login: 'Enter',
    frm_lbl_user: 'User',
    frm_lbl_email: 'E-mail',
    frm_lbl_password: 'Password',
    frm_lbl_confirm_password: 'Confirm Password',

    frm_title_changePassword: 'Change Password',
    frm_subtitle_changePassword: 'Enter the Data Below.',
    frm_btn_changePassword: 'Change',

    frm_lbl_error_password: 'Passwords do not match',

    alr_success_changePassword: 'Password Changed Successfully.',
    alr_error_changePassword: 'Failed to Change Password.',
    alr_success_newPassword: 'New Password: ',
    alr_alert_isChangePassword: 'Do you really want to reset your password?',

    frm_title_firstAccess: 'First Access',
    frm_subtitle_firstAccess: 'Enter the Data of the First User of the System.',
    frm_btn_firstAccess: 'Register',
    frm_lbl_name: 'Name',

    tbl_title_btn_reset_password: 'Reset Password',

    fle_form_title: 'Register File',
    fle_tab_add: 'Register',
    fle_tab_edit: 'List',
    lbl_fle_name: 'File Name (Without Extension)',
    lbl_fle_file: 'File',
    lbl_fle_site: 'Site',
    lbl_fle_user: 'User',
    lbl_fle_success_upload: 'File saved successfully.',
    lbl_fle_error_upload: 'There was an error when trying to save the file',

    lbl_fle_list_remove: 'Remove',
    lbl_fle_remove_item: 'Are you sure you want to delete this file?',
    lbl_fle_remove_success: 'File successfully removed.',
    lbl_fle_remove_error: 'There was an error when trying to remove the file',
};
