import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GlobalModule } from './global.module';

// Components
import { ExampleComponent } from './example/example.component';
import { LoginComponent } from './login/login.component';
import { FirstAccessComponent } from './first-access/first-access.component';

@NgModule({
  declarations: [
    AppComponent,
    ExampleComponent,
    LoginComponent,
    FirstAccessComponent,
  ],
  imports: [
    // Browser
    BrowserModule,
    BrowserAnimationsModule,

    // Router
    AppRoutingModule,

    // Module Global
    GlobalModule,
  ],
  providers: [],
  entryComponents: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
